const Utils = require('./utils.js');

(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const Misc = {

    fixedHeader() {
      const header = $('.c-header--fixed'),
        container = header.find('.c-header__container');

      let threshold = 100;

      if( !header.length || !container.length ) {
        return;
      }

      if( header.hasClass('c-header--none') ) {
        $(window).on('resize', () => {
          if( $(window).width() < 770 ) {
            threshold = 0;
          }else {
            threshold = 100;
          }
        });
      }

      const toggleHeader = function(bgColor, headerInitColor) {
        if( window.pageYOffset >= threshold ) {
          header.addClass('c-header--shrink');
          container.css('background-color', bgColor);
        }else {
          header.removeClass('c-header--shrink');
          if( $(window).width() > 769 ) {
            container.css('background-color', headerInitColor);
          }
        }
      }

      const bgColor     = header.attr('data-fix-header-bg'),
        headerInitColor = container.css('background-color');

      $(window).on('load scroll resize', () => {
        toggleHeader(bgColor, headerInitColor);
      });
    },

    searchForm() {
      const trigger = $('.c-main-nav__search-trigger');

      if( !trigger.length ) {
        return;
      }
      const modal = $('.c-search__modal'),
        close = modal.find('.c-search__close');

      if( modal.length ) {
        trigger.on('click', () => {
          modal.removeClass('u-hidden');
        });

        close.on('click', () => {
          modal.addClass('u-hidden');
        });
      }
    },

    navTrigger() {
      const trigger = $('.c-mobile-nav__trigger'),
        close = $('.c-mobile-nav__close'),
        menu = $('.c-mobile-nav');

      if( !trigger.length || !close.length || !menu.length ) {
        return;
      }

      trigger.on('keydown', e => {
        if (e.which === 13) {
          e.currentTarget.click();
        }
      });

      close.on('keydown', e => {
        if (e.which === 13) {
          e.currentTarget.click();
        }
      });

      trigger.on('click', () => {
        trigger.addClass('c-mobile-nav__trigger--open');
        close.addClass('c-mobile-nav__trigger--open');

        setTimeout(() => {
          menu.addClass('c-mobile-nav--open');
        }, 100); // eslint-disable-line no-magic-numbers
      });

      close.on('click', () => {
        trigger.removeClass('c-mobile-nav__trigger--open');
        close.removeClass('c-mobile-nav__trigger--open');

        setTimeout(() => {
          menu.removeClass('c-mobile-nav--open');
        }, 100); // eslint-disable-line no-magic-numbers
      });
    },

    fullDropdown() {
      const dropdown = $('.c-main-nav__full-dropdown');

      if (!dropdown.length) {
        return;
      }

      const nav = $('.c-main-nav'),
        navItem = nav.find('.c-main-nav__item'),
        dropdownInner = dropdown.find('.c-main-nav__full-dropdown-inner')

      // navItem.on('mouseout', () => {
      //   $(window).bind('mousemove', (e) => {
      //     const self = $(e.target);
      //     if (!self.hasClass('c-main-nav__full-dropdown') && !self.closest('.c-main-nav__full-dropdown').length && !self.closest('.c-main-nav__item').length) {
      //       dropdown.removeClass('show');
      //       navItem.removeClass('c-main-nav__full-dropdown-parent');
      //       dropdownInner.html('');

      //       $(window).unbind('mousemove');
      //     }
      //   });
      // });

      navItem.on('mouseenter', (e) => {
        const self = $(e.currentTarget);
        let delay;

        clearTimeout(delay);
        dropdown.removeClass('show');
        //dropdownInner.html('');

        if (self.hasClass('menu-item-has-children')) {
          delay = setTimeout(() => {
            dropdownInner.html(self.find('.c-main-nav__dropdown--level1').html());
            dropdown.addClass('show');
            navItem.removeClass('c-main-nav__full-dropdown-parent');
            self.addClass('c-main-nav__full-dropdown-parent');
          }, 200);
        }
      });

      dropdown.on('mouseleave', () => {
        let delayLeave;
        clearTimeout(delayLeave);

        delayLeave = setTimeout(() => {
          dropdown.removeClass('show');
          navItem.removeClass('c-main-nav__full-dropdown-parent');
          //dropdownInner.html('');
        }, 200);
      });
    },

    navClosedToggle() {
      const nav = $('.c-main-nav--closed');
      const trigger = nav.find('.c-main-nav__trigger');

      if (!nav.length) {
        return;
      }

      let leave = nav.hasClass('c-main-nav--has-search') ? 2 : 1;

      const items = nav.find('li').not(`:nth-last-child(-n+${leave})`);

      items.hide().css('opacity', 0);
      nav.css('opacity', 1);

      trigger.on('keydown', e => {
        if (e.which === 13) {
          e.currentTarget.click();
        }
      });

      trigger.on('click', () => {
        nav.toggleClass('c-main-nav--open');

        if (nav.hasClass('c-main-nav--open')) {
          items.show()
            .stop()
            .animate({
              opacity: 1
            }, 300);

          setTimeout( () => {
            trigger.attr('aria-expanded', true);
            Misc.mainNavDropdownPosition();
          }, 10);
        }else {
          items
            .stop()
            .animate({
              opacity: 0
            }, 300, () => {
              items.hide();
              trigger.attr('aria-expanded', false);
            });
        }
      });
    },

    mainNavDropdownPosition() {
      const nav = $('.c-main-nav__items'),
        dropdowns = nav.find('.c-main-nav__dropdown');

      if( !dropdowns.length ) {
        return;
      }

      let windowWidth = $(window).width();

      dropdowns.css({ display: 'block', opacity: 0, });

      $.each(dropdowns, (i, el) => {
        const dropdown = $(el);

        let offsetLeft = dropdown.offset().left;

        const multi = dropdown.find('.c-main-nav__dropdown--level3').length ? 2 : 1;

        let width = dropdown.hasClass('c-main-nav__dropdown--inverse') && !dropdown.hasClass('c-main-nav__dropdown--level1') ? dropdown.width() + (dropdown.closest('.c-main-nav__dropdown').width() * multi) : dropdown.width() * multi;

        if( dropdown.hasClass('c-main-nav__dropdown--level3') ) {
          width = width * 2;
        }

        if( offsetLeft < 0 ) { // ie fix - returns negative offset for elements with transform translate3d
          const parent = dropdown.closest('.c-main-nav__item');

          offsetLeft = parent.offset().left + width;
        }

        const posLeft = offsetLeft + width;

        if( posLeft > windowWidth ) {
          dropdown.addClass('c-main-nav__dropdown--inverse');

          if( dropdown.hasClass('c-main-nav__dropdown--level2') || dropdown.hasClass('c-main-nav__dropdown--level3') ) {
            dropdown.parent().addClass('c-main-nav__subitem--inverse');
            const level3 = dropdown.find('.c-main-nav__dropdown--level3');
            if( level3.length ) {
              level3.parent().addClass('c-main-nav__subitem--inverse');
            }
          }
        }else {
          dropdown.removeClass('c-main-nav__dropdown--inverse');
          if( dropdown.hasClass('c-main-nav__dropdown--level2') || dropdown.hasClass('c-main-nav__dropdown--level3') ) {
            dropdown.parent().removeClass('c-main-nav__subitem--inverse');
          }
        }
      });

      dropdowns.css({ display: '', opacity: 1, });
    },

    mainNavExpandable() {
      const navLink = $('.c-main-nav__item, .c-main-nav__subitem');

      navLink.on('mouseenter focusin', e => {
        const self = $(e.currentTarget);

        if (self.hasClass('menu-item-has-children')) {
          const link = self.find('a').first();

          if (link.length) {
            link.attr('aria-expanded', true);
          }
        }
      }).on('mouseleave focusout', e => {
        const self = $(e.currentTarget);

        if (self.hasClass('menu-item-has-children')) {
          const link = self.find('a').first();

          if (link.length) {
            link.attr('aria-expanded', false);
          }
        }
      });
    },

    footerColumnsPadding() {
      const headers = $('.c-footer__column').find('.c-heading'),
        columnPadding = $('.c-footer__column-padding');

      if( !headers.length ) {
        return;
      }

      headers.css('min-height', '1px');
      const maxHeight = Utils.getMaxHeight(headers);

      if( $(window).width() > 768 ) { // eslint-disable-line no-magic-numbers
        headers.css('min-height', maxHeight);

        if( columnPadding.length ) {
          columnPadding.css('padding-top', maxHeight + parseInt(headers.css('margin-bottom')));
        }
      }else {
        headers.css('min-height', '1px');
        columnPadding.css('padding-top', '');
      }
    },

    peopleEqualHeights() {
      const people = $('.c-members'),
        elements = people.find('.c-member'),
        every = people.attr('data-equal-every'); // eslint-disable-line no-magic-numbers

      if( !people.length || !every || typeof every == 'undefined') {
        return;
      }

      const EqualEvery = $.parseJSON( every );

      let nth = EqualEvery[0];

      if( EqualEvery.length > 1 ) {
        nth = people.hasClass('has-sidebar') ? EqualEvery[0] : EqualEvery[1];
      }

      if( nth == 0 ) {
        return;
      }

      const setEqual = (nth) => {
        for(let i = 0; i < elements.length; i+=nth) {
          const group = elements.slice(i, i+nth),
            items = [
              '.c-member__image',
              '.c-member__title',
              '.c-member__dept-pos',
              // '.c-member__departments',
              // '.c-member__address',
              // '.c-member__hours',
              // '.c-member__specialty',
              // '.c-member__email',
              // '.c-member__phone',
              // '.c-member__info',
              '.c-member__content',
            ];

          Utils.equalHeightElements( items, group, 768); // eslint-disable-line no-magic-numbers
        }
      };

      setEqual(nth);

      $(window).on('resize', () => {
        setEqual(nth);
      });
    },

    offerBoxesEqualHeights() {
      const elements = $('.c-page-block__text-columns-box'),
        nth = 2; // eslint-disable-line no-magic-numbers

      for(let i = 0; i < elements.length; i+=nth) {
        const group = elements.slice(i, i+nth),
          items = [
            '.c-page-block__text-columns-content',
            '.c-page-block__text-columns-content ul',
          ];

        Utils.equalHeightElements( items, group, 768); // eslint-disable-line no-magic-numbers
      }
    },

    sidebarFooterHeaderHeight() {
      const sidebar = $('.c-footer__sidebar'),
        column = sidebar.find('.c-footer__sidebar-column'),
        header = column.find('.c-sidebar__header');

      header.css('min-height', '1px');
      const maxH = Utils.getMaxHeight(header);

      if( sidebar.length && header.length ) {
        header.css('min-height', maxH);
      }

      if( column.length > header.length ) {
        $.each(column, (i, el) => {
          const col = $(el),
            colHeader = col.find('.c-sidebar__header');

          if( !colHeader.length ) {
            col.css('padding-top', Utils.getMaxHeight(header, true) - 10 ); // eslint-disable-line no-magic-numbers
          }
        });
      }
    },

    tableSorter() {
      const table = $('.table-sortable');

      if( !table.length ) {
        return;
      }

      table.tablesorter({
        headerTemplate: '{content} {icon}',
        cssIcon: 'tablesorter-icon-default',
        cssIconAsc: 'tablesorter-icon-asc',
        cssIconDesc: 'tablesorter-icon-desc',
      });
    },

    tableResponsive() {
      const tables = $('table.c-x5__table');

      if( !tables.length ) {
        return;
      }

      tables.each( (i, elem) => {
        const table = $(elem),
          header = table.find('thead'),
          labels = [];

        if( !header.length ) {
          table.addClass('table--no-header');
          return;
        }

        if( header.find('th').length ) {
          header.find('th').each( (i, label) => {
            labels.push( $(label).text() );
          });

          if( table.find('tr').length ) {
            table.find('tr').each( (i, row) => {
              if( i > 0 ) { // eslint-disable-line no-magic-numbers
                const cols = $(row).find('td');

                if( cols.length ) {
                  for(let i=0; i<cols.length; i++) {
                    $(cols[i]).attr('data-col-label', labels[i]).wrapInner('<div class="c-td__inner"></div>');
                  }
                }
              }
            });
          }
        }
      });
    },

    videoEmbededLoad() {
      const video = $('.video-wrap');

      if( video.length ) {
        video.animate({
          opacity: 1
        }, 300); // eslint-disable-line no-magic-numbers
      }
    },

    selectBoxes() {
      const selectField = $('select');

      selectField.select2({
        width: '100%',
        minimumResultsForSearch: -1,
      }).on('select2:select', (e) => {
        const self = $(e.target);

        if( self.hasClass('js-select-redirect') && self.val() != '' && self.val() != 'none' ) {
          window.location.href = self.val();
        }

        if( self.hasClass('js-select-submit') ) {
          self.closest('form').submit();
        }
      }).on('select2:open', (e) => {
        const self = $(e.target),
          container = $('.select2-container--open').not('.select2'),
          options   = container.find('.select2-results__options'),
          classes   = self.attr('data-container-css-class');

        options.closest('.select2-dropdown').addClass(classes);

        if (self.hasClass('c-form_-control--first-as-label')) {
          options.addClass('first-as-label');
        }else {
          options.removeClass('first-as-label');
        }
      }).css('opacity', 1);
    },

    lazyload() {
      $('.c-header__container').on('lazyloaded', () => {
        $('.c-header__image-overlay').css('opacity', 1);
      });
    },

    pageHeroScrollDown() {
      const hero = $('.c-page__hero'),
        header = $('.c-header'),
        arrow = hero.find('.c-page__hero-scrolldown');

      if( !arrow.length ) {
        return;
      }

      arrow.on('click', () => {
        const heroHeight = hero.outerHeight() + header.outerHeight();

        $('html, body').animate({
          'scrollTop': heroHeight
        }, 500); // eslint-disable-line no-magic-numbers
      });
    },

    photoGalleryOnEdge() {
      const gallery = $('.c-gallery__single');

      if( !gallery.length ) {
        return;
      }

      setTimeout( () => {
        Utils.galleryItemOnEdge(gallery);
      }, 100);
    },

    contentMoreToggle() {
      const contentMore = $('.c-content__more');

      if (!contentMore.length) {
        return;
      }

      contentMore.each((i, el) => {
        const content = $(el);
        const text = content.find('.c-content__more-text');
        const btn = content.find('.c-content__more-btn');

        const ps = content.find('p');

        if( ps ) {
          ps.each((i, el) => {
            const self = $(el);

            if (self.html().replace('/\s|&nbsp;/g', '').length === 0) {
              self.remove();
            }
          });
        }

        btn.on('click', e => {
          e.preventDefault();

          if (text.hasClass('is-open')) {
            text.removeClass('is-open');
            text.slideUp(200);
            btn.text('Show more').attr('aria-expanded', false);
          }else {
            text.addClass('is-open');
            text.slideDown(200);
            btn.text('Show less').attr('aria-expanded', true);
          }
        });
      })
    },

    dropdownOnTab() {
      const mainNavLink = $('.c-main-nav__link');
      const mainNavSubLink = $('.c-main-nav__sublink');
      const mainNavSubItemLevel1 = $('.c-main-nav__subitem--level1');
      const mainNavSubLinkLevel1 = $('.c-main-nav__sublink--level1');
      const mainNavSubItemLevel2 = $('.c-main-nav__subitem--level2');
      const mainNavSubLinkLevel2 = $('.c-main-nav__sublink--level2');
      const mainNavSubItemLevel3 = $('.c-main-nav__subitem--level3');

      mainNavLink.on('mouseover', () => {
        $('.c-main-nav__items li').removeClass('has-dropdown-open');
      });

      mainNavSubLink.on('focus', e => {
        const self = $(e.currentTarget);

        if ( self.hasClass('focus-visible') ) {
          mainNavLink.parent().removeClass('has-dropdown-open');
          self.parentsUntil('.c-main-nav__items', 'li').addClass('has-dropdown-open');
        }
      });

      mainNavLink.on('focus', e => {
        const self = $(e.currentTarget);

        if ( self.hasClass('focus-visible') ) {
          mainNavLink.parent().removeClass('has-dropdown-open');
          mainNavSubLink.parent().removeClass('has-dropdown-open');

          if (self.parent().hasClass('menu-item-has-children')) {
            self.parent().addClass('has-dropdown-open');
          }
        }
      });

      mainNavSubLinkLevel1.on('focus', e => {
        const self = $(e.currentTarget);

        if ( self.hasClass('focus-visible') ) {
          mainNavSubItemLevel1.removeClass('has-dropdown-open');
          mainNavSubItemLevel2.removeClass('has-dropdown-open');
          mainNavSubItemLevel3.removeClass('has-dropdown-open');

          if ( self.parent().find('.c-main-nav__dropdown--level2').length ) {
            self.parent().addClass('has-dropdown-open');
          }
        }
      });

      mainNavSubLinkLevel2.on('focus', e => {
        const self = $(e.currentTarget);

        if ( self.hasClass('focus-visible') ) {
          mainNavSubItemLevel2.removeClass('has-dropdown-open');

          if ( self.parent().find('.c-main-nav__dropdown--level3').length ) {
            self.parent().addClass('has-dropdown-open');
          }
        }
      });
    },

    init() {
      $(document).ready( () => {
        this.lazyload();
        this.searchForm();
        this.navTrigger();
        this.fullDropdown();
        this.tableSorter();
        this.tableResponsive();
        this.fixedHeader();
        this.navClosedToggle();
        this.contentMoreToggle();
        this.dropdownOnTab();
      });

      $(window).on('load', () => {
        this.selectBoxes();
        this.footerColumnsPadding();
        this.peopleEqualHeights();
        this.videoEmbededLoad();
        this.offerBoxesEqualHeights();
        this.sidebarFooterHeaderHeight();
        this.mainNavDropdownPosition();
        this.mainNavExpandable();
        this.pageHeroScrollDown();
        this.photoGalleryOnEdge();
      });

      $(window).on('resize', () => {
        //this.fixedHeader();
        this.footerColumnsPadding();
        this.offerBoxesEqualHeights();
        this.sidebarFooterHeaderHeight();
        this.mainNavDropdownPosition();
      });
    }

  }

  module.exports = Misc;

})(jQuery, window, document);
